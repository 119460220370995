import { useRouter } from 'next/router'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import LoginModal from '@components/auth/LoginModal'
import { setAuthRequired } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'

import Footer from './Footer'
import Nav from './Nav'

const DesktopLayout = ({ children }) => {
  const { isLoggedIn, authRequired } = useAppSelector((state) => state.currentUser)
  const dispatch = useDispatch()
  const router = useRouter()

  return (
    <div className="flex min-h-screen flex-col bg-white">
      <Nav />
      <div className={`flex-1 relative`}>{children}</div>
      {!router.pathname?.includes('revona') && router.pathname !== '/application' && <Footer />}
      {!isLoggedIn && authRequired.loginRequired && (
        <LoginModal
          onClose={() => {
            dispatch(setAuthRequired(false))
            if (authRequired.onDismissURL) {
              router.push(authRequired.onDismissURL)
            }
          }}
        />
      )}
    </div>
  )
}

export default DesktopLayout
